<template>
  <div class="generic-list scroll-container">
    <CategoryTitle
      :category="category"
      :class="{ 'hide-description': showActivateDigitalReceipt }"
    />

    <div
      v-if="showActivateDigitalReceipt"
      class="pb-6"
      v-html="category.metaData.category_info.FOOTER_DESCRIPTION"
    ></div>
    <ReceiptsListFilter
      class="mb-5"
      @updateReceiptsFilter="updateReceiptsFilter"
      :digital="digital"
      :minDate="minDate"
      v-if="!showActivateDigitalReceipt"
    />
    <div
      v-if="digital"
      class="primary--text white darken-1 rounded-sm mt-6 py-2 px-6 d-flex justify-space-between"
    >
      <h3 class="d-flex align-center">
        <strong>{{
          showActivateDigitalReceipt
            ? $t("receipts.activateDigitalReceipts")
            : $t("receipts.deactivateDigitalReceipts")
        }}</strong>
      </h3>
      <v-switch
        color="success"
        @click="switchDigitalReceipts"
        readonly
        :input-value="digitalActive"
        :loading="digitalActiveLoading"
      ></v-switch>
    </div>
    <ReceiptsTable
      v-if="receipts && receipts.length > 0"
      :receipts="receipts"
      :digital="digital"
    />
  </div>
</template>
<style lang="scss">
.hide-description {
  div:nth-child(2) {
    display: none;
  }
}
</style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import ReceiptsTable from "@/components/orders/ReceiptsTable.vue";
import ReceiptsListFilter from "@/components/orders/ReceiptsListFilter.vue";

import categoryMixin from "~/mixins/category";
import reload from "~/mixins/reload";

import TigrosRegistrationService from "@/service/tigrosRegistrationService";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "Orders",
  props: {
    digital: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      filterData: {},
      receipts: [],
      receiptPager: {},
      start: 1,
      receiptType: null,
      digitalActiveLoading: false,
      promovar: "promovar_89555"
    };
  },
  mixins: [categoryMixin, reload],
  components: {
    CategoryTitle,
    ReceiptsListFilter,
    ReceiptsTable
  },
  computed: {
    ...mapGetters({
      pageFilter: "category/pageFilter",
      user: "cart/getUser"
    }),
    selectedReceiptType() {
      return this.receiptType ? (this.receiptType == "home" ? 2 : 1) : null;
    },
    showActivateDigitalReceipt() {
      return !this.digitalActive && this.digital;
    },
    digitalActive() {
      let crm = this.user.crmUserSegments.find(
        e => e.crmSegment.code == this.promovar
      );
      if (crm && crm.value) {
        return crm.value == 1;
      } else return false;
    },
    minDate() {
      let min = this.user.crmUserSegments.find(
        e => e.crmSegment.code == "promovar_89556"
      );

      if (min) {
        let value = min.value;
        let parsed = this.$dayjs(value, "YYMMDD");
        let currentDate = this.$dayjs();

        if (parsed.isBefore(currentDate.subtract(3, "months"))) {
          return currentDate.subtract(3, "months").toDate();
        } else {
          return parsed.toDate();
        }
      } else {
        return this.$dayjs()
          .subtract(3, "month")
          .toDate();
      }
    }
  },
  methods: {
    ...mapActions({
      loadCart: "cart/loadCart"
    }),
    getFormattedDate(date) {
      let d = this.$dayjs(date).format("DD/MM/YYYY");
      return d;
    },
    updateReceiptsFilter(filterData) {
      this.filterData = filterData;
      this.reload();
    },
    async switchDigitalReceipts() {
      this.digitalActiveLoading = true;
      let response = await TigrosRegistrationService.updatePromovar(
        this.promovar,
        this.digitalActive ? "0" : "1"
      );
      if (
        response.data &&
        response.data.value &&
        response.data.value.success === true
      ) {
        //test al contrario perchè ho aggiornato la variabile ma non ho anccora riletto lo user
        if (this.digitalActive) {
          this.receipts = null;
        } else {
          await TigrosRegistrationService.updatePromovar(
            "promovar_89556",
            this.$dayjs().format("YYMMDD")
          );
        }
        this.loadCart();
        global.EventBus.$emit("updateDashboard");
      }
      this.digitalActiveLoading = false;
    },
    reload() {
      TigrosRegistrationService.getReceiptList(
        this.getFormattedDate(this.filterData.dateBegin),
        this.getFormattedDate(this.filterData.dateEnd),
        "",
        "",
        null,
        this.start - 1,
        this.filterData.orderTypeSelect == "bollette" ? true : false,
        this.filterData.orderTypeSelect == "garanzie" ? true : false,
        this.selectedReceiptType,
        false,
        this.digital
      ).then(data => {
        // this.orders = this.filterData.orderTypeSelect
        //   ? data.orders.filter(
        //       order =>
        //         order.deliveryAddress.addressType ==
        //         this.filterData.orderTypeSelect
        //     )
        //   : data.orders;
        // this.orderPager = data.page;
        this.receipts = data.receipts;
      });
    }
  }
};
</script>
