var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"generic-list receipts-table"},[_c('v-list',{staticClass:"mt-4 receipts-list"},_vm._l((_vm.receipts),function(receipt){return _c('v-list-item',{key:receipt.id,staticClass:"primary--text secondary lighten-2 rounded-sm pa-4"},[_c('v-row',{staticClass:"text-caption text-sm-body-2 px-0",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('div',{staticClass:"field-wrapper"},[_c('div',{staticClass:"f-label"},[_vm._v(_vm._s(_vm.$t("receipts.header.date")))]),_c('div',{staticClass:"f-value"},[_vm._v(" "+_vm._s(_vm.$dayjs(receipt.date).format("DD/MM/YYYY HH:mm"))+" ")])])]),_c('v-col',{attrs:{"cols":"6","md":"2"}},[_c('div',{staticClass:"field-wrapper"},[_c('div',{staticClass:"f-label"},[_vm._v(_vm._s(_vm.$t("receipts.header.amount")))]),_c('div',{staticClass:"f-value"},[_vm._v(" "+_vm._s(_vm.$n(receipt.total, "currency"))+" ")])])]),_c('v-col',{attrs:{"cols":"6","md":"4"}},[_c('div',{staticClass:"field-wrapper"},[_c('div',{staticClass:"f-label"},[_vm._v(_vm._s(_vm.$t("receipts.header.pointOfSale")))]),_c('div',{staticClass:"f-value"},[_vm._v(" "+_vm._s(receipt.storeName)+" ")])])]),_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('div',{staticClass:"field-wrapper"},[_c('div',{staticClass:"f-label"},[_vm._v(_vm._s(_vm.$t("receipts.header.paymentType")))]),_c('div',{staticClass:"f-value"},[_vm._v(" "+_vm._s(receipt.infos.paymentType)+" ")])])]),_c('v-col',{attrs:{"cols":"0","md":"8"}}),_c('v-col',{staticClass:"d-flex justify-end mt-3",attrs:{"cols":"12","md":"4"}},[(_vm.digital)?_c('v-btn',{attrs:{"small":"","depressed":"","color":"primary","height":"40","block":"","to":{
              name: 'DigitalReceipt',
              params: { receiptId: receipt.id },
              query: {
                bill: receipt.infos.bill,
                warranty: receipt.infos.warranty,
                paymentType: receipt.infos.paymentType
              }
            }}},[_vm._v(" "+_vm._s(_vm.$t("receipts.receiptTable.display"))+" ")]):_c('v-btn',{attrs:{"small":"","depressed":"","color":"primary","height":"40","to":{
              name: 'Receipt',
              params: { receiptId: receipt.id },
              query: {
                bill: receipt.infos.bill,
                warranty: receipt.infos.warranty,
                paymentType: receipt.infos.paymentType
              }
            }}},[_vm._v(" "+_vm._s(_vm.$t("receipts.receiptTable.display"))+" ")])],1)],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }