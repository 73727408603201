<template>
  <div class="generic-list receipts-table">
    <v-list class="mt-4 receipts-list">
      <v-list-item
        v-for="receipt in receipts"
        :key="receipt.id"
        class="primary--text secondary lighten-2 rounded-sm pa-4"
      >
        <v-row
          no-gutters
          align="center"
          class="text-caption text-sm-body-2 px-0"
        >
          <v-col cols="6" md="3">
            <!-- data -->
            <div class="field-wrapper">
              <div class="f-label">{{ $t("receipts.header.date") }}</div>
              <div class="f-value">
                {{ $dayjs(receipt.date).format("DD/MM/YYYY HH:mm") }}
              </div>
            </div>
          </v-col>

          <!-- totale -->
          <v-col cols="6" md="2">
            <div class="field-wrapper">
              <div class="f-label">{{ $t("receipts.header.amount") }}</div>
              <div class="f-value">
                {{ $n(receipt.total, "currency") }}
              </div>
            </div>
          </v-col>

          <!-- receipt type -->
          <v-col cols="6" md="4">
            <div class="field-wrapper">
              <div class="f-label">{{ $t("receipts.header.pointOfSale") }}</div>
              <div class="f-value">
                {{ receipt.storeName }}
              </div>
            </div>
          </v-col>

          <!-- pagamento -->
          <v-col cols="6" md="3">
            <div class="field-wrapper">
              <div class="f-label">{{ $t("receipts.header.paymentType") }}</div>
              <div class="f-value">
                {{ receipt.infos.paymentType }}
              </div>
            </div>
          </v-col>

          <v-col cols="0" md="8"></v-col>
          <v-col cols="12" md="4" class="d-flex justify-end mt-3">
            <v-btn
              v-if="digital"
              small
              depressed
              color="primary"
              height="40"
              block
              v-bind:to="{
                name: 'DigitalReceipt',
                params: { receiptId: receipt.id },
                query: {
                  bill: receipt.infos.bill,
                  warranty: receipt.infos.warranty,
                  paymentType: receipt.infos.paymentType
                }
              }"
            >
              {{ $t("receipts.receiptTable.display") }}
            </v-btn>
            <v-btn
              v-else
              small
              depressed
              color="primary"
              height="40"
              v-bind:to="{
                name: 'Receipt',
                params: { receiptId: receipt.id },
                query: {
                  bill: receipt.infos.bill,
                  warranty: receipt.infos.warranty,
                  paymentType: receipt.infos.paymentType
                }
              }"
            >
              {{ $t("receipts.receiptTable.display") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-list-item>
    </v-list>
  </div>
</template>
<style lang="scss">
.receipts-table {
  .active {
    background-color: var(--v-primary-lighten2) !important;
  }
  .small {
    font-size: 10px;
    min-height: 16px;
    text-transform: uppercase;
  }
  .field-wrapper {
    margin-bottom: 15px;
    font-size: 14px;
    .f-value {
      font-weight: bold;
      span {
        align-items: center;
      }
    }
  }
}
</style>
<script>
import cartInfo from "~/mixins/cartInfo";

import { mapState, mapGetters } from "vuex";

export default {
  name: "ReceiptsTable",
  props: {
    receipts: {
      type: Array
    },
    delivered: {
      type: Boolean
    },
    digital: {
      type: Boolean,
      default: false
    }
  },
  mixins: [cartInfo],
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getItemByProductId: "cart/getItemByProductId"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    timeslot() {
      return this.getItemByProductId(this.product.productId);
    },
    quantity() {
      return this.item ? this.item.quantity : 0;
    },
    canIRateOrder() {
      return this.order.orderStatusId === 8 || this.order.orderStatusId === 18;
    }
  },
  methods: {
    rateOrder(orderId) {
      this.$router.push("/order/" + orderId);
    },
    async deleteOrder(orderId) {
      let res = await this.$dialog.confirm({
        text: global.vm.$t("message.removeOrder", {
          orderId: orderId
        })
      });
      if (res) {
        this.$emit("delete", orderId);
      }
    },
    editOrder(orderId) {
      this.$emit("editOrder", orderId);
    },
    addAllToCart(orderId) {
      this.$emit("addAllToCart", orderId);
    }
  },
  mounted() {}
};
</script>
